.container {
    padding: 16px;
  }
  
  .input {
    width: 2ch;
    color:white;
    padding: 8px;
    border-radius: 8px;
    font-size: 40px;
    text-align: center;
    margin-right: 12px;
    border: 1px solid white;
    background-color: rgb(21, 85, 141);
    text-transform: uppercase;
  }