.time_container {
    display: flex;
    flex-wrap: wrap;
}

.time_row {
    border-radius: 3%;
    color: white;
    padding: 3%;
    font-size: 10px;
    margin: 1%;
    margin-top: 10%;
    background-color: #4DAA57;
}

.time_row button {
    background: transparent;
    color: white;
    border: none;
    box-shadow: 0;
}

.time_row button:hover {
    color: #F05365;
}

.page_wrapper_blur {
	filter: blur(2px);
	-webkit-filter: blur(2px);
}


