.jumbotron{
    border-radius:0px!important;
    box-shadow:1px 1px 4px ;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; 
    position: relative;
    height: 100%;
    margin-left: 15%;
    margin-right: 10%;


}

.jumbotron .btn{
    border-radius:0px!important;
    box-shadow:1px 1px 4px ;
    padding:10px 40px;
}