body {
    box-sizing: content-box;
}

thead {
    background-color: rgb(17, 119, 181);
}

.bg-color {
    background-color: rgb(206, 228, 239);
}

h5,
.text-color {
    color: rgb(17, 119, 181);
    font-family: 'Montserrat', sans-serif;
}

.table {
    --bs-table-striped-bg: rgb(206, 228, 239) !important;
}

.columns {
    column-count: 1;
    column-gap: 1em;
    column-rule: 0.3em solid black;
}